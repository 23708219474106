import "core-js/modules/es.array.push.js";
import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
export default {
  name: 'YLGuide',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const type = ref(0);
    onMounted(() => {
      console.log(route.params.type);
      type.value = route.params.type;
    });
    const toHis = () => {
      router.push('/station/clinic');
    };
    return {
      toHis,
      type
    };
  }
};