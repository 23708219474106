import { reactive } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup() {
    // do not use same name with ref
    const router = useRouter();
    const form = reactive({
      name: '',
      region: '',
      date1: '',
      date2: '',
      delivery: false,
      type: [],
      resource: '是',
      desc: ''
    });
    const src = 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d518a48jpeg.jpeg';
    const onSubmit = () => {
      console.log('submit!');
    };
    const goBack = () => {
      router.go(-1);
    };
    return {
      form,
      onSubmit,
      src,
      goBack
    };
  }
};