import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

import user from '@/store/modules/user'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user
  },
  plugins: [
    createPersistedstate({
      key: 'yyhl-vue',
      paths: ['user']
    })
  ]
})
