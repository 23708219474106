import "core-js/modules/es.array.push.js";
import { ref, reactive } from 'vue';
import { Search } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import Complain from './complain';
import Datao from './datao';
import Issue from './issue';
import Plan from './plan';
import Protocol from './protocol';
export default {
  name: 'Clinic',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Search,
    Complain,
    Datao,
    Issue,
    Plan,
    Protocol
  },
  setup() {
    const router = useRouter();
    const radio = ref('待就诊');
    const searchKey = ref('');
    const circleUrl = 'https://img1.baidu.com/it/u=1881729541,3170519255&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500';
    const squareUrl = 'https://img1.baidu.com/it/u=1881729541,3170519255&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500';
    const changeRadio = value => {
      radio.value = value;
    };
    const chbRegister = () => {
      router.push('/station/chbRegister');
    };
    const personList = reactive([{
      userName: '赵晓雪',
      gender: '女',
      age: '23岁'
    }, {
      userName: '刘辉',
      gender: '男',
      age: '33岁'
    }, {
      userName: '叶如红',
      gender: '女',
      age: '30岁'
    }, {
      userName: '刘永辉',
      gender: '男',
      age: '33岁'
    }, {
      userName: '马达',
      gender: '男',
      age: '26岁'
    }, {
      userName: '周玲玲',
      gender: '女',
      age: '32岁'
    }, {
      userName: '罗梅',
      gender: '女',
      age: '35岁'
    }, {
      userName: '刘超',
      gender: '男',
      age: '23岁'
    }, {
      userName: '杨振',
      gender: '男',
      age: '43岁'
    }, {
      userName: '郭超',
      gender: '男',
      age: '53岁'
    }]);
    const selectPerson = ref({});
    const clickPerson = index => {
      selectPerson.value = personList[index];
      console.log(selectPerson.value);
    };
    return {
      Search,
      radio,
      searchKey,
      changeRadio,
      circleUrl,
      squareUrl,
      chbRegister,
      personList,
      clickPerson,
      selectPerson
    };
  }
};