import "core-js/modules/es.array.push.js";
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
export default {
  name: 'LoginPage',
  setup() {
    const ruleForm = reactive({
      password: '',
      userName: ''
    });
    const router = useRouter();
    const onSubmit = () => {
      router.push('/home');
    };
    return {
      ruleForm,
      onSubmit
    };
  }
};