import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/home'
import Login from '@/views/login'
import Station from '@/views/station'
import Solve from '@/views/solve'
import Clinic from '@/views/clinic'
import Register from '@/views/clinic/register'
import Complain from '@/views/clinic/complain'
import DataO from '@/views/clinic/datao'
import Issue from '@/views/clinic/issue'
import Plan from '@/views/clinic/plan'
import Protocol from '@/views/clinic/protocol'
import YLGuide from '@/views/ylguide'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/station',
    component: Station,
    children: [
      {
        path: '/ylguide/:type',
        name: 'guide',
        component: YLGuide
      },
      {
        path: 'clinic', // 线下问诊
        component: Clinic, // 子路由后面由动态组件替换，路由会销毁重建，动态组件通过<keep-alive></keep-alive> 标签保证组件不被销毁
        children: [
          {
            path: 'complain',
            component: Complain// 主诉
          },
          {
            path: 'sickInfo',
            component: DataO// 客观资料
          },
          {
            path: 'issuedes',
            component: Issue// 问题评估
          },
          {
            path: 'plan',
            component: Plan// 管理计划
          },
          {
            path: 'protocol',
            component: Protocol// 诊疗记录
          }
        ]
      },
      {
        path: 'chbregister',
        component: Register// 患者登记
      }
    ]
  },
  {
    path: '/solve',
    component: Solve
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
