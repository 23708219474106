import { ref } from 'vue';
export default {
  name: 'Station',
  setup() {
    const isCollapse = ref(false);
    const handleOpen = (key, keyPath) => {
      console.log(key, keyPath);
    };
    const handleClose = (key, keyPath) => {
      console.log(key, keyPath);
    };
    const toggleSlide = () => {
      isCollapse.value = !isCollapse.value;
    };
    return {
      isCollapse,
      handleOpen,
      handleClose,
      toggleSlide
    };
  }
};